.modal-header {
  padding: 20px;
}

.modal-content {
  border: none !important;
  background-color: #fff !important;
  border-radius: 15px;
}

.tt-modal .modal-content {
  border-radius: 4px;
  padding: 60px 45px;
}

.tt-modal .modal-content .modal-body {
  padding: 0;
}

.tt-modal .xs {
  max-width: 500px!important;
}

.tt-modal .sm {
  max-width: 600px!important;
}

.tt-modal .md {
  max-width: 700px!important;
}

.tt-modal .xl {
  max-width: 800px!important;
}

.tt-modal .subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #5A6170;
}

.tt-modal .modal-body span {
  display: block;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.85px;
}

.tt-modal .modal-footer {
  padding: 0;
  justify-content: space-around;
}


.react-tel-input .form-control {
  border: none!important;
  border-bottom: 0.5px solid #9da6b8!important;
  width: 100%!important;
  border-radius: 0!important;
}

.flag-dropdown {
  border: none!important;
  border-bottom: 0.5px solid #9da6b8!important;
  border-radius: 0!important;
}

.inputPhoneLabel {
  margin-bottom: 4px;
}

.panel-default .close{display: none;}