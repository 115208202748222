form .codeInput {
  width: 40px!important;
  height: 40px;
  background: #fff;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 700;
  margin: 0 7px;
}

form .codeDiv {
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 9px;
  display: inline-flex;
}

form .codeDiv .form-control:focus {
  border-color: #FF8B03;
}

.codeRow h5 {
  letter-spacing: 0.850962px;
  line-height: 21px;
  font-size: 18px;
  color: #5a6170;
  font-weight: 400;
  margin: 35px 0;
}

.codeRow {
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.codeRow p {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.850962px;
  color: #5a6170;
}
