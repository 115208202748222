.tt-register h2 {
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.85px;
  color: #5a6170;
  margin-bottom: 15px;
}

.tt-register {
  border-radius: 5px 0 0 5px;
  padding: 20px 0;
}

* {
  box-sizing: border-box;
}

/* Estilo Checkbox */

.check-sl .form-check {
  position: relative;
  display: block;
  padding-left: 0;
}

.check-sl input[type=checkbox] + label{
  cursor:pointer;
}

.form-check-input{
  margin-top: 2px!important;
}