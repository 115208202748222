/* events */

.fc-event-main {
    display: flex;
    flex-direction: column;
}

.fc-h-event {
    border: none;
    padding: 10px;
}

.fc-daygrid-event .fc-event_int {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    line-height: 1.4;
    border: none;
    cursor: move;
    font-size: 14px;
    margin: 0;
    padding: 7px 10px;
    background-color: #ccc;
    color: #4632A4 !important;
    z-index: auto;
    box-sizing: border-box;
    align-items: flex-start;
    width: 100%;
}

.fc-day-grid-event .fc-content {
    white-space: normal!important;
}

.fc-button {
    background: #FFF;
    border: 1px solid #ced4da;
    color: #495057;
    text-transform: capitalize;
}

.fc button {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    height: 33px;
    padding: 0 9px;
    font-size: 14px;
    cursor: pointer;
}

.fc-button-primary:disabled {
    color: #4632A4;
    background-color: #FFF;
    border-color: #4632A4;
}

.fc-button-primary:hover {
    color: #fff;
    background-color: #281B65;
    border-color: #281B65;
}

.fc-button:disabled {
    opacity: 0.65;
}

.fc-unthemed td.fc-today {
    background-color: #F5F3FF;
    position: relative;
}

.fc-unthemed td.fc-today .fc-day-number {
    color: #4632A4!important;
    padding-left: 4px;
    padding-top: 2px;
    font-weight: 600;
}

.fc-day-number {
    float: none!important;
    font-size: 12px!important;
    color: #3c4043!important;
}

.fc-day-header span {
    font-size: 14px;
    color: #70757a;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
    border-color: #dadce0;
}

.fc-past .fc-day-number {
    color: #70757a!important;
}

.fc-toolbar h2 {
    color: #70757a!important;
}

.fc-left h2, .fc-center h2, .fc-right h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    padding-bottom: 3px;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 11px;
}

.fc th.fc-widget-header {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: 600;
    text-transform: uppercase;
}

.fc-unthemed td.fc-today .fc-day-number::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #FDAD34;
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: -1;
    border-radius: 100px;
}

/*  Buttons */

.fc .fc-button-primary {
    color: #2C3E50;
    background-color: #fff;
    border-color: #CFD5DA;
    padding: 12px 15px;
}

.fc button {
    height: auto;
}

.fc button:focus {
    outline: 0!important;
    box-shadow: 0 0 0 transparent!important;;
}

.fc .fc-button-active {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

.fc .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #CFD5DA;
}

.fc .fc-scrollgrid.fc-scrollgrid-liquid {
    border-radius: 5px;
}

.fc th.fc-day {
    padding: 15px;
    border: none;
    border-bottom: 1px solid #CFD5DA;
    text-transform: uppercase;
    color: #5A6170;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: #fff;
}

.fc .fc-daygrid-day-number {
    top: 6px;
    right: 6px;
    position: absolute;
    z-index: 999999999;
}

.fc-daygrid-day-number {
    background-color: #fff;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    color: #5A6170;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
    background-color: #FF8B03;
    border-radius: 100px;
    height: 25px;
    width: 25px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc .fc-daygrid-day-top {
    position: relative;
}