.profileContent .avatar {
  width: 118px;
  height: 118px;
}

.profileContent .profilePicture {
  border-right: 1px solid #e5e5e5;
}

.progress {
  background-color: #f2f1f1;
  height: 6px;
  border-radius: 5px;
}

.progress-bar {
  background-color: #3EB243;
}

.userWorkSettings .nav .nav-item .nav-link {
  color: var(--color-text)!important;
  min-width: 200px!important;
  font-size: 14px!important;
  text-align: center!important;
  border: 3px transparent!important;
}

.userWorkSettings .nav .nav-item .nav-link:hover {
  /* font-weight: 500; */
  border: transparent;
}

.userWorkSettings .nav.nav-tabs {
  border-bottom: 1px solid #b5b8c9;
}

.userWorkSettings .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: transparent;
  border: transparent;
  border-bottom: 3px solid #5A6170 !important;
  font-weight: 500;
}

.userWorkSettings .user-file-item p {
  margin: 0;
}

.userWorkSettings .user-file-item .user-file-item-content {
  padding: 0 5px;
}

.userWorkSettings .user-file-item {
  margin-bottom: 40px;
}

.file-upload>input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.file-upload label {
  margin-bottom: 0;
}

.file-upload label:hover {
  cursor: pointer;
}

.profileContent .user-avatar {
  top: -20px;
  position: relative;
  right: -25px;
}

.user-progress {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: right;
  letter-spacing: 0.04em;
}

.profileContentRow .input-group {
  height: fit-content;
}

.profileContentRow .input-group .input-group-text {
  padding: 0;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #9da6b8;
  cursor: pointer;
}

.DatePicker, .DatePicker__input {
  width: 100%!important;
}

.responsive-calendar {
  /* by setting font-size, all the elements will correspond */
  font-size: 9px !important;
  /* default to 10px */
}

.errPrice {
  color: #ff5555!important;
  font-size: 12px!important;
  text-align: center!important;
}

/* .selectorHours:checked {
  background-color: red;
} */

#myTab .nav-link.active {
  color: rgb(30, 30, 30)!important;
  font-weight: 600;
}

.step-number {
  background-color: #FA5805;
  color: #FFFFFF;
  font-weight: 600;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.leaflet-container {
  width: 100%!important;
  height: 100%!important;
  min-height: 800px!important;
  position: relative!important;
}

.table-buttons.active {
  background-color: #000!important;
}

.nav-link {
  color: #5a6170;
}

.ulErr * {
  color: #FF0000!important;
}

.colorErr {
  color: #FF0000!important;
}