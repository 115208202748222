.progress-profile .relative {
    position: relative;
}

.progress-profile .absolute-profile {
    position: absolute;
    top: 0;
    left: 10px;
}

.progress-profile .absolute-medal1 {
    position: absolute;
    bottom: -10px;
    left: 16px;
    z-index: 9;
}

.progress-profile .absolute-medal2 {
    position: absolute;
    bottom: 53px;
    left: -1px;
    z-index: 9;
}

.progress-profile .absolute-medal3 {
    position: absolute;
    bottom: 115px;
    left: 28px;
    z-index: 9;
}

.progress-profile .absolute-medal4 {
    position: absolute;
    bottom: -22px;
    right: 94px;
    z-index: 9;
    top: -12px;
}

.progress-profile .absolute-medal5 {
    position: absolute;
    bottom: 115px;
    right: 28px;
    z-index: 9;
}

.progress-profile .absolute-medal6 {
    position: absolute;
    bottom: 53px;
    right: -1px;
    z-index: 9;
}

.progress-profile .absolute-medal7 {
    position: absolute;
    bottom: -10px;
    right: 16px;
    z-index: 9;
}

.progress-profile .pull-right {
    float: right;
}

.progress-profile .pull-left {
    float: left;
}

.progress-profile .text-center {
    text-align: center;
}

.progress-profile .value {
    margin-top: 40px;
    font-size: 48px;
    font-weight: 500;
}

.progress-profile {
    width: 220px;
}

.progress-profile .progress-profile__overflow {
    width: 220px;
    height: 162px;
    overflow: hidden;
}

.progress-profile .bar {
    width: 200px;
    height: 200px;
    border: 10px solid #EAEDF4;
    border-radius: 50%;
    z-index: 2;
}

.progress-profile .bar--30 {
    transform: rotate(45deg);
}

.RCP {
    position: absolute!important;
    width: 240px!important;
    top: -10px!important;
    right: 0!important;
    left: -10px!important;
    bottom: 0!important;
    width: 10px!important;
    height: 10px!important;
}