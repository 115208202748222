.app {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app .app-layout {
    max-width: 800px;
    width: 100%;
    overflow: hidden;
    height: 700px;
    background-color: #fff;
}

.app .app-layout .blocked {
    min-height: 700px;
    float: left;
}

.app .app-layout .ly {
    display: block;
}

.app .app-layout .ly.login {
    margin-left: -1400px;
    transition: margin .4s;
}

.app .app-layout .ly.register {
    margin-left: -850px;
    transition: margin .4s;
}

.app .app-layout .ly.active {
    margin-left: 0px;
    transition: margin .4s;
}

.app-layout-mobile {
    display: none;
}

.app-layout {
    display: block;
}

.activate-container{max-width: 500px;}

@media(max-width:991px) {
    .app-layout-mobile {
        display: block;
        width: 100%;
    }
    .app-layout {
        display: none;
    }

    .form.tt-register{
        width: 100%!important;
        padding: 0!important;
        margin:0!important;
    }

    .codeInput{
        margin: 0px!important;
    }

    .activate-container{
        width: 100%;
    }
}