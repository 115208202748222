.modal-body {
  padding: 0 30px;
  text-align: center;
}

.modal-body p {
  color: #686868;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0px;
  font-style: normal;
  line-height: 24px;
}

.modal-header {
  padding: 0;
  border-bottom: none;
  padding-right: 7px;
}

.modal-footer {
  border-top: none;
}

.modal a {
  color: #045f92 !important;
  cursor: pointer;
}

.brand span {
  color: #333;
}

.tt-login h2 {
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.85px;
  color: #5a6170;
  margin-bottom: 8px;
}

.recoverPassModalText {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.85px;
  color: #5a6170;
  text-decoration: underline;
  margin-bottom: 0;
}
