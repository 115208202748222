.profile-skills {
    width: 23px;
    height: 23px;
    border-radius: 100%;
    text-align: center;
    line-height: 2.5;
    font-size: 9px;
}

.number-position-ranking {
    font-weight: 600;
    font-size: 16px;
}

.word-position-ranking {
    font-weight: 500;
    font-size: 12px;
}

.mark-position-ranking {
    background-color: #cde1f7;
    /*color: #FFF !important;*/
    font-weight: 500;
}

.bg-green {
    background-color: #3EB243;
    color: #FFF;
    font-weight: 500;
}

.bg-blue {
    background-color: #4666DE;
    color: #FFF;
    font-weight: 500;
}

.bg-grey {
    background-color: #48639C;
    color: #FFF;
    font-weight: 500;
}

.bg-table-thead {
    background-color: rgb(249, 249, 249);
}

.bg-table-tbody {
    background-color: rgb(241 244 250);
}

.table-responsive table tbody tr td {
    vertical-align: middle;
    padding: 8px !important;
}
