#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: calc(100vh - 75px);
  margin-left: -12rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  position: initial;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.04em;
  line-height: 15px;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 200px;
}

#page-content-wrapper {
  width: 100%;
  background-color: #F5F6FB;
  padding: 0;
  overflow-y: auto;
  height: calc(100vh - 65px);
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  #wrapper.toggled #sidebar-wrapper {
    margin-left: -200px;
  }
}

@media (max-width: 991px) {
  #sidebar-wrapper {
    position: absolute;
    z-index: 999999;
    margin-left: -14rem;
  }

  #sidebar-wrapper .list-group {
    width: 100%;
  }

  #page-content-wrapper {
    padding: 5px !important;
  }
}
