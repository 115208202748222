.wrapper-responsive {
  margin: 0 auto;
  max-width: 100%;
  height: 100%;
}

.cell-responsive{
  width: 100vw;
}

.table-responsive {
  width: 100%;
  display: table;
}

@media screen and (max-width: 991px) {
  .table {
    display: block;
  }
}

.row-responsive {
  display: table-row;
  background: #f6f6f6;
}

.row-responsive:nth-of-type(odd) {
  background: #e9e9e9;
}

.row-responsive.header-responsive {
  font-weight: 900;
  color: #ffffff;
  background: #ccc;
}

@media screen and (max-width: 991px) {
  .row-responsive {
    padding: 14px 0 7px;
    display: block;
  }
  .row-responsive.header-responsive {
    padding: 0;
    /* height: 6px; */
  }
  .row-responsive.header-responsive .cell-responsive {
    display: none;
  }
  .row-responsive .cell-responsive {
    margin-bottom: 10px;
  }
  .row-responsive .cell-responsive:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.cell-responsive {
  padding: 12px;
  display: table-cell;
}

@media screen and (max-width: 991px) {
  .cell-responsive {
    padding: 2px 16px;
    display: block;
  }
}

.no-data {
  height: 60px;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data p {
  margin: 0;
  color: #555;
}

.TableSelectHoursCheck {
  display: none;
}

.TableSelectHoursLabel {
  text-align: left;
  padding: 8px 12px;
  border-radius: 4px;
  color: #fff;
  background: #fff;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 0.5px;
  width: 100%;
  height: 100%;
}

/* .TableSelectHoursCheck:checked + label.TableSelectHoursLabel svg path{
  fill: #000
} */

.TableSelectHoursCheck:checked+label.TableSelectHoursLabel {
  background: #000;
}

/* .cell-responsive{
  max-width: 100px;
} */

.pagination {
  display: flex;
  justify-content: left;
  width: 100%;
}

.pagination button {
  color: #264348;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: none;
  margin: 0 3px;
  border-radius: 4px;
  background-color: white;
}

.pagination button.active {
  background-color: #264348;
  color: white;
}

.pagination button:hover:not(.active) {
  background-color: #d2d7d3;
}

.container-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

a.disabled {
  pointer-events: none;
  color: #ccc;
}

.container-flex {
  display: flex;
  width: 100%;
}

.col-filter {
  display: flex;
  width: 50%;
  padding: 0 5px;
}

.col-perpage {
  display: flex;
  width: 25%;
  padding: 0 5px;
}

.col-order {
  display: flex;
  width: 35%;
  padding: 0 5px;
}

.col-sort {
  display: flex;
  width: 25%;
  padding: 0 5px;
}

.col-pagination {
  display: flex;
  width: 75%;
  padding: 0 5px;
}

.col-exports{
  display: flex;
  width: 25%;
  padding: 0 5px;
  justify-content: flex-end;
}

.pagination-input {
  display: flex;
  align-items: center;
  width: 100%;
}

.select-label {
  display: flex;
  align-items: center;
  width: 50%;
}

.select-label label {
  padding: 0;
  margin: 0;
}

.select-select {
  display: flex;
  align-items: center;
  width: 50%;
}

.order-label {
  display: flex;
  align-items: center;
  width: 40%;
}

.order-label label {
  padding: 0;
  margin: 0;
}

.order-list {
  display: flex;
  align-items: center;
  width: 60%;
}

.order-list .relative {
  width: 100%;
  background: #f1f1f1;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.col-perpage .relative {
  width: 100%;
  background: #f1f1f1;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.filter-label {
  display: flex;
  align-items: center;
  width: 15%;
}

.filter-label label {
  padding: 0;
  margin: 0;
}

.filter-input {
  display: flex;
  align-items: center;
  width: 45%;
}

.filter-input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  border-radius: 0;
  outline: 0!important;
  padding: 10px;
}

.filter-select {
  display: flex;
  align-items: center;
  width: 30%;
}

.filter-select .relative {
  width: 100%;
  background: #f1f1f1;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.control-container {
  padding: 15px;
}

.sort-label {
  display: flex;
  align-items: center;
  width: 35%;
}

.sort-label label {
  padding: 0;
  margin: 0;
}

.sort-list {
  display: flex;
  align-items: center;
  width: 65%;
}

.sort-list .relative {
  width: 100%;
  background: #f1f1f1;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
}

.exports-label {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}

.exports-label label {
  padding: 0;
  margin: 0;
}

.exports-list{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}

.exports-list ul {
  padding: 0;
  margin: 0;
}

.exports-list ul li {
  display: inline-flex;
  margin: 0 5px;
  cursor: pointer;
  align-items: center;
}

.exports-list ul li svg path {
  fill: #5a6170
}



.bktable-container{
  position: relative;
}

.bktable-footer{
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
}