.agenda-title{
	font-size: 14px;
	margin: 0;
	color: #fff;
}
.agenda-date{
	font-size: 12px;
	margin: 0;
	color: #fff;
}

.bxCalendar{
	color: #fff;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.tachado {
    text-decoration: line-through;
}