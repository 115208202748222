.ulServ {
    padding: 0;
    margin: 0;
    margin-bottom: 20px!important;
}

.ulServ li {
    margin: 15px;
    list-style: none;
    padding: 15px;
    cursor: pointer;
}

.ulServ li.active {
    background: #000;
    color: #fff;
}

.ul-inline li {
    display: inline-block;
    width: 100%;
    max-width: 60px;
}

.attributeCheck input {
    display: none;
}

.attributeCheck input:checked+label .check {
    display: block;
}

.attributeCheck label .check{
    display: none;
}

.attributeCheck label {
    cursor: pointer;
}

.attributeCheck svg path {
    fill: green
}