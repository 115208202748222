.card-container {
  background-color: #fff;
  margin: 15px 0;
  border-radius: 5px;
  /*box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.03);*/
}

.card-container .card-title {
  border-bottom: 1px solid #eee;
  padding: 15px;
}

.card-container .card-title span {
  font-size: 16px;
  font-weight: 300;
  color: #333;
}

.card-container .card-body {
  padding: 0;
}

.bg-black {
  background-color: #25323f;
}

.color-white {
  color: #FFF;
}